import angular from 'angular';
import MODULE from './module';

// templates
import editTemplate from './admin.companies.edit.html';
import preferencesTemplate from './admin.companies.preferences.html';
import securityTemplate from './admin.companies.security.html';
import advancedOptionsTemplate from './admin.companies.view.advance-options.html';
import vulnerabilitySummaryTemplate from './admin.companies.vulnerability-summary-report.html';

const app = angular.module(MODULE);

app.controller('ManagementAdminCompanyController', [
  '$uibModal',
  '$rootScope',
  '$scope',
  '$state',
  '$stateParams',
  '$translate',
  '$log',
  '$window',
  '$location',
  'AuthService',
  'AdminApiService',
  'FeatureFlagsApi',
  'FeatureFlags',
  'USER_ROLES',
  'ErrorHandler',
  'AdminBulkManageUsersCSV',
  'AdminBulkReInviteUsers',
  'ExportUsersCSVFn',
  'ExportAuditLogCSVFn',
  'DownloadAvailableContentCSVFn',
  '$swal',
  'AnalyticsService',
  function (
    $uibModal,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $translate,
    $log,
    $window,
    $location,
    AuthService,
    AdminApiService,
    FeatureFlagsApi,
    FeatureFlags,
    USER_ROLES,
    ErrorHandler,
    AdminBulkManageUsersCSV,
    AdminBulkReInviteUsers,
    ExportUsersCSVFn,
    ExportAuditLogCSVFn,
    DownloadAvailableContentCSVFn,
    $swal,
    AnalyticsService
  ) {
    $('.management-css').prop('disabled', false);

    // @tmp
    $window._company = $scope;
    var metadata = $rootScope.metadata;

    // @tmp
    $window._company = $scope;

    // Pre-fetch state load
    var companyId = $stateParams.companyId;
    var company = angular.copy($stateParams.company || false);
    var redirectToFeatures = $stateParams.redirectToFeatures;

    // Begin: ui preferences
    // $scope.$$company = {};
    // End: ui preferences

    // Begin: exposed data
    $scope.companyId = companyId;
    $scope.company = company;
    $scope.apicenter = $window.SCW_ENV.ApiEndpoint + '/api/';
    $scope.dateNow = Date.now();
    $scope.unassigned = { state: false };
    $scope.regions = metadata.management.regions;
    $scope.industries = metadata.management.industries;
    $scope.redirectToFeatures = redirectToFeatures;

    $scope.tabs = {
      0: 'Company Administrators',
      1: 'Teams',
      3: 'Reports',
      4: 'Audit Logs',
    };
    // End: exposed data

    // Begin: exposed methods
    $scope.auth = AuthService;
    $scope.reload = init;
    $scope.userSearch = {}; // bound controller
    $scope.getCompany = getCompany;
    $scope.getAdditionalData = getAdditionalData;
    $scope.editCompany = editCompany;
    $scope.clickedIntegrations = clickedIntegrations;
    $scope.configureLearningModules = configureLearningModules;
    $scope.goToVulnerabilitySummaryPage = goToVulnerabilitySummaryPage;
    $scope.editCompanyPreferences = editCompanyPreferences;
    $scope.editCompanySecurity = editCompanySecurity;
    $scope.bulkManage = AdminBulkManageUsersCSV;
    $scope.bulkReInviteCompany = bulkReInviteCompany;

    $scope.downloadCSV = downloadCSV;
    $scope.exportUserListCSV = exportUserListsCSV;
    $scope.exportAuditLogCSV = exportAuditLogCSV;

    $scope.downloadAvailableContentCSV = downloadAvailableContentCSV;
    $scope.go = $state.go;
    $scope.showAdvancedOptions = showAdvancedOptions;
    $scope.isMetricsEnabled = $rootScope.isTrainingAllowedByCustomerPlan;
    $scope.isActivitySummaryEnabled =
      $rootScope.isTrainingAllowedByCustomerPlan && $rootScope.isAssessmentAllowedByCustomerPlan;
    $scope.earlyAccessClick = () => {
      AnalyticsService.logPageViewEvent($location.absUrl(), 'Early Access Modal', 'modal');
    };
    // End: exposed methods

    function setCompany(val) {
      if (!val) return;
      company = val;
      $scope.company = company;
      $scope.$emit('admin:company-loaded', company);
      return getAdditionalData(company);
    }

    function reloadCompany() {
      // reload is full-reload, including children
      $state.go('admin.company', { companyId: $scope.company._id }, { reload: true });
    }

    function clickedIntegrations() {
      $state.go('.integrations', { companyId: $scope.company._id, company: $scope.company });
    }

    function configureLearningModules() {
      $state.go('admin/learning-modules', { path: `/${$scope.company._id}` });
    }

    function goToVulnerabilitySummaryPage() {
      $uibModal
        .open(
          {
            templateUrl: vulnerabilitySummaryTemplate,
            controller: 'ManagementAdminCompanyVulnerabilitySummaryController',
            size: 'md',
            windowClass: 'eight-hundred-modal',
            scope: $scope,
            backdrop: 'static',
          },
          'edit'
        )
        .result.then(reloadCompany)
        .catch(angular.noop);
    }

    function getCompany(companyId) {
      return AdminApiService.getCompany(companyId).then(setCompany);
    }

    function getAdditionalData(company) {
      if (company._id) {
        AdminApiService.getAnonymisation(companyId).then(function ({ anonymise }) {
          $scope.anonymise = !!anonymise;
        });
      }
    }

    function init() {
      $scope.enableCustomerAnalytics = false;
      FeatureFlagsApi.isFeatureEnabled(FeatureFlags.CUSTOMER_ANALYTICS).then((flag) => {
        $scope.enableCustomerAnalytics = flag;
      });

      $scope.enableVulnerabilitySummaryReport = false;
      FeatureFlagsApi.isFeatureEnabled(FeatureFlags.VULNERABILITY_SUMMARY_REPORT).then((flag) => {
        $scope.enableVulnerabilitySummaryReport = flag;
      });

      $scope.enableNewAdminDashboard = false;
      FeatureFlagsApi.isFeatureEnabled(FeatureFlags.DASHBOARD).then((flag) => {
        $scope.enableNewAdminDashboard = flag;
      });

      $scope.hideLeagcyAdminConfiguration = false;
      FeatureFlagsApi.isFeatureEnabled(FeatureFlags.HIDE_LEGACY_ADMIN).then((flag) => {
        $scope.hideLeagcyAdminConfiguration = flag;
      });

      $scope.companyId = companyId; // force "child" components reload.
      if (
        !companyId &&
        $scope.isAuthorized(USER_ROLES.companyAdmin) &&
        !$scope.isAuthorized([USER_ROLES.reseller, USER_ROLES.admin])
      ) {
        if ($scope.Session.user.properties._cid) {
          return $state.go($scope.redirectToFeatures ? 'admin.company.features' : 'admin.company', {
            companyId: $scope.Session.user.properties._cid,
            company: $scope.Session.user.properties.company,
          });
        } else {
          return ErrorHandler.addError($translate.instant('USER_COMPANY_DATA_INVALID'));
        }
      }

      getCompany($scope.companyId).then(setCompany);

      $scope.$watch('$$company.ui.selectedTab', function (newTab) {
        AnalyticsService.logPageViewEvent($location.absUrl(), $scope.tabs[newTab], 'tab');
      });
    }

    // edit company details
    function editCompany(company) {
      company = company || $scope.company;
      var modalInstance = $uibModal.open({
        templateUrl: editTemplate,
        controller: 'EditCompanyModalController',
        size: 'lg',
        scope: $scope,
        backdrop: 'static',
        resolve: {
          company: company,
        },
      });
      // Refresh company incase the api keys were generated.
      modalInstance.result.finally(function () {
        getCompany(company._id);
      });
      return modalInstance;
    }

    function editCompanyPreferences() {
      return $uibModal
        .open(
          {
            templateUrl: preferencesTemplate,
            controller: 'CompanyPreferencesModalController',
            size: 'lg',
            scope: $scope,
            backdrop: 'static',
          },
          'edit'
        )
        .result.catch(angular.noop);
    }

    function editCompanySecurity() {
      return $uibModal
        .open(
          {
            templateUrl: securityTemplate,
            controller: 'CompanySecurityModalController',
            size: 'lg',
            scope: $scope,
            backdrop: 'static',
            resolve: {
              company: function () {
                return $scope.company;
              },
            },
          },
          'add'
        )
        .result.then(reloadCompany)
        .catch(angular.noop);
    }

    /*** intro tours ***/
    $translate([
      'NEXT',
      'PREVIOUS',
      'CLOSE',
      'FINISH',
      'TOUR_COMPANY_ADMIN_OPTIONS_1',
      'TOUR_COMPANY_ADMIN_OPTIONS_2',
      'TOUR_COMPANY_ADMIN_OPTIONS_3',
      'TOUR_COMPANY_ADMIN_OPTIONS_4',
      'TOUR_COMPANY_ADMIN_OPTIONS_5',
    ]).then(function (translations) {
      $scope.tourCompanyAdminOptions = {
        steps: [
          {
            intro: translations.TOUR_COMPANY_ADMIN_OPTIONS_1,
          },
          {
            element: '#company-admin-actions-bar',
            intro: translations.TOUR_COMPANY_ADMIN_OPTIONS_2,
            position: 'top',
          },
          {
            element: '#company-admins-section',
            intro: translations.TOUR_COMPANY_ADMIN_OPTIONS_3,
            position: 'top',
          },
          {
            element: '#searchFilter-tab',
            intro: translations.TOUR_COMPANY_ADMIN_OPTIONS_4,
            position: 'top',
          },
          {
            element: '#teams-section',
            intro: translations.TOUR_COMPANY_ADMIN_OPTIONS_5,
            position: 'top',
          },
        ],
        showStepNumbers: false,
        exitOnOverlayClick: false,
        exitOnEsc: true,
        nextLabel: translations.NEXT,
        prevLabel: translations.PREVIOUS,
        skipLabel: translations.CLOSE,
        doneLabel: translations.FINISH,
        overlayOpacity: 0.9,
        showBullets: false,
      };
    });

    function exportUserListsCSV() {
      var query = {};
      query['properties._cid'] = $scope.company._id;
      query['roles'] = [USER_ROLES.companyAdmin, USER_ROLES.manager, USER_ROLES.player];

      return ExportUsersCSVFn(query);
    }

    function exportAuditLogCSV() {
      var query = {};
      query['properties._cid'] = $scope.company._id;
      query['roles'] = [USER_ROLES.companyAdmin, USER_ROLES.manager, USER_ROLES.player];

      return ExportAuditLogCSVFn(query);
    }

    function downloadAvailableContentCSV(contentType) {
      const query = {
        ['properties._cid']: $scope.company._id,
        contentType,
      };

      return DownloadAvailableContentCSVFn(query);
    }

    function downloadCSV(type) {
      var sendTarget;
      var optionsObj;

      sendTarget = { _id: company._id, type: 'company' };
      optionsObj = {
        target: sendTarget,
        type: type,
      };

      AdminApiService.getLeaderboardCSV(optionsObj, null)
        .then(function (data) {
          $translate(
            [
              'CSV_REPORT_READY',
              'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
            ],
            { dataUrl: data.url }
          ).then(function (translations) {
            var isIE = /Trident|MSIE/.test(window.navigator.userAgent);
            if (window.navigator.msSaveBlob && isIE) {
              // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
              swal({
                title: translations.CSV_REPORT_READY,
                text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
                type: 'success',
                html: true,
                showCancelButton: false,
                showConfirmButton: false,
              });
            } else {
              document.location.href = data.url;
              swal.close();
            }
          });
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_TYPE_LEADERBOARD_CSV'], { type: type }).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_TYPE_LEADERBOARD_CSV, response);
          });
        });

      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    }

    function showAdvancedOptions() {
      return $swal({
        title: $translate.instant('ADMIN_COMPANIES_VIEW_ADVANCED_OPTIONS_TITLE'),
        templateUrl: advancedOptionsTemplate,
        showConfirmButton: false,
        showCancelButton: false,
        scope: $scope.$new(),
      }).then(function (response) {
        $log.debug('[AdminCompaniesView] ng-submit option response', response);

        if (response === 'metrics') return $scope.go('metrics', { t1: company._id });
        if (response === 'preferences') return $scope.editCompanyPreferences();
        if (response === 'edit') return $scope.editCompany();
        if (response === 'bulkManage') return $scope.bulkManage(company);
        if (response === 'security') return $scope.editCompanySecurity();
      });
    }

    function bulkReInviteCompany() {
      return AdminBulkReInviteUsers.bulkReInviteCompany(companyId);
    }

    // lifecycle
    init();
  },
]);
