// A partial list of LaunchDarkly feature flag names.
// See also the list in projects/portal-frontend/scw-angular/feature-flags/FeatureFlagsApi.js
export const featureFlags = {
    CISO_VALUE_EMAIL: 'cisoValueReport',
    COMMS_CENTRE: 'comms-centre',
    COMMS_CENTRE_LEGACY: 'comms-centre-legacy',
    COMMS_CENTRE_QUILL: 'comms-centre-quill',
    CUSTOMER_ANALYTICS: 'customer-analytics',
    CUSTOMER_PLAN: 'customer-plan',
    CYBERMON: 'cybermon',
    DASHBOARD: 'dashboard-page',
    DEMO_REPORTING: 'demo-reporting',
    HIDE_LEGACY_ADMIN: 'hide-legacy-admin',
    HIDE_TRAINING: 'hide-training',
    LEVEL_GROUPING: 'level-grouping-feature',
    MISSION_IN_COURSES: 'MISSION_IN_COURSES',
    MULTI_ASSESSMENTS: 'multi-assessments',
    MULTI_COMPANY_TOURNAMENT_BANNER: 'multi-company-tournament-banner',
    MY_QUESTS_SKILL_LEVEL_VISIBLE: 'my-quests-skill-level-visible',
    NEW_TOURNAMENTS_LIST: 'new-tournaments-list-page',
    NEW_USER_MANAGEMENT_PAGE: 'new-user-management-page',
    MANAGEMENT_CSV: 'management-csv',
    ONBOARDING_APP: 'onboarding-app',
    PUBLIC_TOURNAMENTS: 'public-tournaments',
    QUESTS_ADMIN_SECURITY_CONCEPT_OBJECTIVE: 'quests-admin-security-concept-objective',
    QUESTS_LMS: 'quests-lms',
    REPORTING: 'reporting',
    SHOW_EXPLORE_MENU_ITEM: 'show-explore-menu-item',
    TOURNAMENT_INVITES: 'tournament-invites',
    VULNERABILITY_SUMMARY_REPORT: 'vulnerability-summary-report',
};
